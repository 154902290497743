import React from 'react';
import { RelatedCategoriesCarousel, ConfigProvider } from '@nmg/pdp-components';

const RenderRelatedCategories = ({relatedCategories, device}) => {
    return (relatedCategories ? (
      <>
        <ConfigProvider device={device}>
          <div className="related-categories redesign grid-100 tablet-grid-100" style={{marginBottom: '10px'}}>
            <RelatedCategoriesCarousel categories={relatedCategories}/>
          </div>
        </ConfigProvider>
      </>
    ) : null
  );
  }

  export default RenderRelatedCategories;